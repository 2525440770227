/*Title section*/
.txt-righ {
    text-align: right;
}

.mb-bt15 {
    margin-bottom: 15px;
}

.loader {
    text-align: center;
}

/*table section*/
.table thead {
    background: #081c42;
}

.table thead tr th {
    color: white;
    text-align: center;
    border-right: 1px solid;
}

.table tbody tr td {
    color: rgb(0, 0, 0);
    text-align: center;
    vertical-align: middle;
    border-right: 1px solid white;
    font-family: Arial, Helvetica, sans-serif;
}

/*button section*/
.button {
    border: 1px solid rgb(221, 221, 221);
    border-radius: 0.130rem;
    background-color: #ffffff;
    border-color: #e7e7e7;
    color: #040427;
    width: 150px;
    font-weight: bold;
}

.button:hover {
    border: 1px solid rgb(48, 48, 48);
    border-radius: 0.15rem;
    background-color: #ffffff;
    border-color: #c2c2c2;
    color: #000000;
}

.button:active {
    border: 1px solid rgb(48, 48, 48);
    border-radius: 0.15rem;
    background-color: #ffffff;
    border-color: #c2c2c2;
    color: #03145c;
}

/* Modal Section */
.mb-10 {
    margin-bottom: 15px;
}

.modal-footer {
    justify-content: center !important;
    flex-wrap: initial;
    border-top: 0px !important;
}

.containers > input {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.sub-title {
    margin-bottom: 20px;
    font-size: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: rgb(109, 109, 109);
}

.sub-title > p {
    margin: 0px;
}

.section-button-save {
    text-align: right;
    float: right;
}

