.flex {
  display: flex;
}

.internal-sidebar {
  width: 18rem;

  border-right: 1px solid gainsboro;
  background-color: white;
}

.internal-sidebar-text-main {
  padding-top: 30px;
}

.internal-sidebar-text-main > b {
  padding-left: 25px;
  font-weight: bold;
  font-size: 13px;
  color: #afafaf;
  font-family: Verdana, sans-serif;
}

.internal-sidebar-text {
  padding-top: 20px;
  margin-bottom: 10px;
}

.internal-sidebar-text > b {
  padding-left: 25px;
  font-weight: bold;
  font-size: 13px;
  color: #afafaf;
  font-family: Verdana, sans-serif;
}

.internal-sidebar > ul {
  list-style: none;
  padding: 0.5rem 0rem;
  margin: 0px;
}

.internal-sidebar > ul > li {
  position: relative;
  height: 50px;
  padding-left: 40px;
}

.internal-sidebar > ul > li:hover {
  position: relative;
  height: 50px;
  padding-left: 40px;
  background-color: rgb(238, 238, 238);
}

.internal-sidebar > ul > li:active {
  position: relative;
  height: 50px;
  padding-left: 40px;
  background-color: rgb(238, 238, 238);
}

.internal-sidebar > ul > li > a {
  text-decoration: none;
  font-size: 15px;
  position: absolute;
  top: 30%;
  color: rgb(66, 66, 66);
  font-family: Verdana, sans-serif;
  line-height: 20px;
}

.internal-sidebar > ul > li > a > svg {
  display: inline-block;
  vertical-align: top;
  font-size: 20px
}
