/*section*/
.mt-30 {
    margin-top: 10px;
}

/*section*/
.mr-5 {
    margin-right: 5px;
}

.wthd-100 {
    width: 100%;
}

.resume-title {
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
}

.resume-container {
    width: 100%;
    padding: 20px;
    border: 1px solid #eeeeee;
    background-color: #f8f8f8;
}