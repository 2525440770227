li {
    margin-top: 10px;
    text-decoration: none;
    font-size: 15px;
    color: rgb(66, 66, 66);
    font-family: Verdana, sans-serif;
}

li a{
    text-decoration: none;
    font-size: 16px;
    color: rgb(66, 66, 66);
    font-family: Verdana, sans-serif;
}

li a:hover{
    text-decoration: none;
    font-size: 16px;
    color: rgb(66, 66, 66);
    font-family: Verdana, sans-serif;
}

li.active {
    margin-top: 10px;
    background-color: rgb(238, 238, 238);
    text-decoration: none;
    font-size: 15px;
    color: rgb(66, 66, 66);
    font-family: Verdana, sans-serif;
}

li.active a{
    text-decoration: none;
    font-size: 16px;
    color: rgb(66, 66, 66);
    font-family: Verdana, sans-serif;
}

li.active span {
    text-decoration: none;
    font-size: 16px;
    font-family: Verdana, sans-serif;
}

.cxRqFw {
    background-color: rgb(238, 238, 238) !important;
}

.hnPgzf {
    background-color: rgb(247 247 247) !important;
}