.navbar-th {
    background-color: #081c42;
    padding: 0rem 2rem;
    padding-top: 0.5rem;
    vertical-align: middle;
    -webkit-box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.62);
    -moz-box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.62);
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.62);
    position: relative;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .navbar {
      background-color: #081c42;
      padding: 0.5rem 2rem;
      padding-top: 0.5rem;
      vertical-align: middle;
      -webkit-box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.62);
      -moz-box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.62);
      box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.62);
      position: relative;
    }
  }