.App {
  height: 100%;
}

.request-header {
  padding-bottom: 15px;
  padding-top: 15px;
  padding-left: 60px;
  border-bottom: 1px solid rgb(231, 231, 231);
  font-size: 25px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: rgb(109, 109, 109);
}

.request-header > p {
  margin: 0px;
}

.request-form {
  padding-left: 60px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 60px;
  height: auto;
}

.request-button {
  float: right;
}

.request-button > button {
  width: 150px;
  height: 40px;
  background-color: rgb(17, 102, 69);
  margin-bottom: 30px;
}

.request-button > button:hover {
  width: 150px;
  height: 40px;
  background-color: rgb(33, 134, 95);
  margin-bottom: 30px;
}