.pagination-table {
    float: right;
    margin-bottom: 0px;
}

.pagination > li > a {
    color: #081c42;
}

.active>.page-link {
    background-color: #081c42 !important;
    border-color: #081c42 !important;
}

.pagination {
    --bs-pagination-color: #686868 !important;
}