.container {
    background-color: white;
    height: -webkit-fill-available;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.container-fluid {
    top: 30%;
    position: relative;
}

.text-containers {
    position: inherit;
    text-align: center;
}

.text-containers > button {
    width: 150px;
    height: 50px;
    background-color: rgb(53, 170, 164);
}

.text-containers > button:hover {
    width: 150px;
    height: 50px;
    background-color: rgb(60, 190, 184);
}

.text-containers > p > b {
    text-align: center;
    text-decoration: none;
    font-size: 70px;
    font-family: Arial, Helvetica, sans-serif;
    color: gray;
}

.text-containers > p {
    text-align: center;
    text-decoration: none;
    font-size: 60px;
    font-family: Arial, Helvetica, sans-serif;
    color: gray;
}   

p {
    margin: 0px;
}

@media screen and (max-width: 640px) {
    .text-containers > p > b {
        text-align: center;
        text-decoration: none;
        font-size: 30px;
        font-family: Arial, Helvetica, sans-serif;
        color: gray;
    }
    
    .text-containers > p {
        text-align: center;
        text-decoration: none;
        font-size: 20px;
        font-family: Arial, Helvetica, sans-serif;
        color: gray;
    }   
    
    .text-containers > button {
        width: 120px;
        height: 45px;
        font-size: 10px;
        background-color: rgb(53, 170, 164);
    }
    
    .text-containers > button:hover {
        width: 120px;
        height: 45px;
        font-size: 10px;
        background-color: rgb(60, 190, 184);
    }
  }
  
  @media screen and (min-width: 640px) and (max-width: 1280px) {
    .text-containers > p > b {
        text-align: center;
        text-decoration: none;
        font-size: 50px;
        font-family: Arial, Helvetica, sans-serif;
        color: gray;
    }
    
    .text-containers > p {
        text-align: center;
        text-decoration: none;
        font-size: 35px;
        font-family: Arial, Helvetica, sans-serif;
        color: gray;
    }  

    .text-containers > button {
        width: 130px;
        height: 45px;
        font-size: 10px;
        background-color: rgb(53, 170, 164);
    }
    
    .text-containers > button:hover {
        width: 130px;
        height: 45px;
        font-size: 10px;
        background-color: rgb(60, 190, 184);
    }
  }
  
  .css-1ciqy06-MuiPaper-root-MuiCard-root {
    height: 100% ;
    border: 0px;
  }

  .css-1ciqy06-MuiPaper-root-MuiCard-root iframe {
    height: 100% ;
    border: 0px;
  }