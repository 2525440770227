
.flex {
  display: flex;
  height: 100%;
}

.request-header {
  padding-bottom: 15px;
  padding-top: 15px;
  padding-left: 50px;
  border-bottom: 1px solid rgb(231, 231, 231);
  font-size: 25px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: rgb(109, 109, 109);
  background-color: white;
  height: 70px !important;
}

.request-header > p {
  margin: 0px;
}

.wd-100 {
  height: 100%;
}

.internal-content {
  flex: auto;
  position: relative;
  margin: 30px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}