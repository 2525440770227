.navbar-containers {
    height: 50px;
}


.navbar-containers-icon {
    height: 50px;
    display: inline-grid;
}  

@media screen and (max-width: 640px) {
    .navbar-containers-icon {
        height: 50px;
        display: inline-grid;
    }
}

@media screen and (min-width: 640px) and (max-width: 1280px) {
    .navbar-containers-icon {
        height: 50px;
        margin-left: 25px;
        display: inline-grid;
    }   
}

.user{
    float: right;
    margin-top: 5px;
}