.modal-group {
    padding: 20px;
}

.mt-20{ 
    margin-top: 50px;
}

.section-button-request{
    text-align: center;
    float: initial;
}