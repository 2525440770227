/*Title section*/
.txt-righ {
    text-align: right;
}

.mb-bt30 {
    margin-bottom: 30px;
}

.loader {
    text-align: center;
}

/*table section*/
.table {
    margin-bottom: 0px;
}
.table thead {
    background: #070746;
}

.table thead tr th {
    color: white;
    text-align: center;
    border-right: 1px solid;
}

.table tbody tr td {
    color: rgb(0, 0, 0);
    text-align: center;
    vertical-align: middle;
    border-right: 1px solid white;
    font-family: Arial, Helvetica, sans-serif;
}

/*button section*/
.button {
    border: 1px solid rgb(48, 48, 48);
    border-radius: 0.15rem;
    background-color: #ffffff;
    border-color: #c2c2c2;
    color: #000000;
    margin-left: 5px;
}

.button b {
    background-color: #ffffff;
    border-color: #c2c2c2;
    color: #03145c;
}

.button:hover {
    border: 1px solid rgb(48, 48, 48);
    border-radius: 0.15rem;
    background-color: #ffffff;
    border-color: #c2c2c2;
    color: #000000;
}

.button b:hover {
    background-color: #ffffff;
    border-color: #c2c2c2;
    color: #03145c;
}

.button:active {
    border: 1px solid rgb(48, 48, 48);
    border-radius: 0.15rem;
    background-color: #ffffff;
    border-color: #c2c2c2;
    color: #03145c;
}

/*th section size*/
.th-action {
    text-align: center;
    width: 150px;
}

.th-year {
    text-align: center;
    width: 150px;
}

.th-month {
    text-align: center;
    width: 300px;
}

/* Modal Section */
.mb-15 {
    margin-bottom: 15px;
}

.mb-10 {
    margin-bottom: 18px;
}
