.custom-color {
  color: white !important;
  background-color: #1c3d80;
  border-color: #1c3d80;
}

.custom-color:hover {
  color: white !important;
  background-color: #204694;
  border-color: #204694;
}
